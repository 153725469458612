import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs, getDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from './firebaseConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { SiweMessage } from 'siwe';
import { checksumAddress, createPublicClient, encodeFunctionData, http, parseUnits } from 'viem';
import { baseSepolia } from 'viem/chains';
import {
  createSmartAccountClient,
  ENTRYPOINT_ADDRESS_V06,
} from 'permissionless';
import { privateKeyToSimpleSmartAccount } from 'permissionless/accounts';
import { createPimlicoPaymasterClient } from 'permissionless/clients/pimlico';

const rpcUrl = 'https://api.developer.coinbase.com/rpc/v1/base-sepolia/MjedAxltHoKi2ys6lIIF0SArbTTM-TlM';
const usdcContractAddress = '0x036CbD53842c5426634e7929541eC2318f3dCF7e';

const BillList = ({ provider }) => {
  const [bills, setBills] = useState([]);
  const [userAddress, setUserAddress] = useState('');

  useEffect(() => {
    const getUserAddress = async () => {
      try {
        const addresses = await provider.request({ method: 'eth_requestAccounts' });
        setUserAddress(checksumAddress(addresses[0]));
      } catch (error) {
        console.error('Error getting user address:', error);
        toast.error('Please connect your wallet');
      }
    };

    getUserAddress();
  }, [provider]);

  useEffect(() => {
    if (userAddress) {
      fetchBills();
    }
  }, [userAddress]);

  const fetchBills = async () => {
    try {
      const billsQuery = query(collection(db, 'bills'), where('address', '==', userAddress));
      const querySnapshot = await getDocs(billsQuery);
      if (querySnapshot.empty) {
        toast.error(`You don't have bills. Create bills first.`);
        return;
      }
      const billsList = querySnapshot.docs.map(doc => ({ docId: doc.id, ...doc.data() }));
      setBills(billsList);
    } catch (error) {
      console.error('Error fetching bills:', error);
      toast.error('Failed to fetch bills');
    }
  };

  const signInWithEthereum = async (provider) => {
    try {
      const message = new SiweMessage({
        domain: window.location.host,
        address: userAddress, 
        statement: 'Sign in with Ethereum to the application.',
        uri: window.location.origin,
        version: '1',
        chainId: 1,
      });

      const messageToSign = message.prepareMessage();
      const signature = await provider.request({
        method: 'personal_sign',
        params: [messageToSign, userAddress],
      });

      const recoveredAddress = await message.recoverAddress(signature);
      if (recoveredAddress.toLowerCase() !== userAddress.toLowerCase()) {
        throw new Error('Address mismatch');
      }

      return true;
    } catch (error) {
      console.error('Error during SIWE sign-in:', error);
      toast.error('Sign-In with Ethereum failed.');
      return false;
    }
  };

  const payBill = async(bill) => {
    try {
      toast.success(`Paying ${bill.title} with $${bill.amount} to ${bill.merchantAddress}`);

      const billsDocRef = doc(db, 'bills', bill.docId); 
      const billDoc = await getDoc(billsDocRef);

      if (!billDoc.exists()) {
        toast.error('Selected bill does not exist');
        return;
      }

      const isAuthenticated = await signInWithEthereum(provider);

      if (!isAuthenticated) {
        toast.error('Sign In With Ethereum Failed');
        return;
      }

      const publicClient = createPublicClient({
        transport: http(rpcUrl),
      });

      const simpleAccount = await privateKeyToSimpleSmartAccount(publicClient, {
        privateKey: '0x38fcd15834fd4db1c299d708d22e208f9c81c0c5a47920e7fa9d6b5954d3f750',
        factoryAddress: '0x9406Cc6185a346906296840746125a0E44976454',
        entryPoint: ENTRYPOINT_ADDRESS_V06,
      });

      const pimlicoPaymaster = createPimlicoPaymasterClient({
        chain: baseSepolia,
        transport: http(rpcUrl),
        entryPoint: ENTRYPOINT_ADDRESS_V06,
      });

      const smartAccountClient = createSmartAccountClient({
        account: simpleAccount,
        chain: baseSepolia,
        bundlerTransport: http(rpcUrl),
        middleware: {
          sponsorUserOperation: pimlicoPaymaster.sponsorUserOperation,
        },
      });

      const minTokenAbi = [
        {
          inputs: [
            { internalType: 'address', name: 'to', type: 'address' },
            { internalType: 'uint256', name: 'value', type: 'uint256' },
          ],
          name: 'transfer',
          outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
          stateMutability: 'nonpayable',
          type: 'function',
        },
        {
          inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
          name: 'balanceOf',
          outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
          stateMutability: 'view',
          type: 'function',
        },
        {
          inputs: [],
          name: 'decimals',
          outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
          stateMutability: 'view',
          type: 'function',
        },
      ];

      const decimals = await publicClient.readContract({
        address: usdcContractAddress,
        abi: minTokenAbi,
        functionName: 'decimals',
      });

      const balance = await publicClient.readContract({
        address: usdcContractAddress,
        abi: minTokenAbi,
        functionName: 'balanceOf',
        args: [userAddress],
      });

      const amount = parseUnits(bill.amount.toString(), decimals);
      if (parseUnits(balance) < amount) {
        toast.error('Insufficient USDC balance');
        return;
      }

      const callData = encodeFunctionData({
        abi: minTokenAbi,
        functionName: 'transfer',
        args: [bill.merchantAddress, amount.toString()],
      });

      try {
        const txHash = await smartAccountClient.sendTransaction({
          account: smartAccountClient.account,
          to: usdcContractAddress,
          data: callData,
          value: 0n,
        });

        console.log("✅ Transaction successfully sponsored!");
        toast.success(`✅ Transaction successful. 🔍 View on Etherscan: https://sepolia.basescan.org/tx/${txHash}`);

        await updateDoc(billsDocRef, { paid: true }); // Update the bill status in Firestore

        toast.success('Bill paid successfully.');
      } catch (sponsorError) {
        console.error('Error during sponsored transaction:', sponsorError);
        toast.error('Sponsored transaction failed. Trying fallback method.');

        try {
          const callDataFallback = encodeFunctionData({
            abi: minTokenAbi,
            functionName: 'transfer',
            args: [bill.merchantAddress, amount.toString()],
          });
          const txFallback = await provider.request({
            method: 'eth_sendTransaction',
            params: [
              {
                from: userAddress,
                to: usdcContractAddress,
                data: callDataFallback,
                value: '0x0', 
              },
            ],
          });

          console.log("✅ Transaction successfully paid using user's gas!");
          console.log(`🔍 View on Etherscan: https://sepolia.basescan.org/tx/${txFallback}`);

          await updateDoc(billsDocRef, { paid: true }); // Update the bill status in Firestore

          toast.success('Bill paid successfully.');
        } catch (fallbackError) {
          console.error('Error during fallback transaction:', fallbackError);
          toast.error('Transaction failed.');
        }
      }
    } catch (error) {
      console.error('Error during bill payment:', error);
      toast.error('Failed to pay bill.');
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <ToastContainer />
      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Bill List</h2>
        {bills.length > 0 ? (
          <table className="min-w-full bg-gray-800 text-white">
            <thead>
              <tr>
                <th className="py-2 px-4">Title</th>
                <th className="py-2 px-4">Amount (USD)</th>
                <th className="py-2 px-4">Due Date</th>
                <th className="py-2 px-4">Merchant Address</th>
                <th className="py-2 px-4">Recurring</th>
                <th className="py-2 px-4">Interval (days)</th>
                <th className="py-2 px-4">Status</th>
                <th className="py-2 px-4">Action</th>
              </tr>
            </thead>
            <tbody>
              {bills.map(bill => (
                <tr key={bill.docId}>
                  <td className="border-t border-gray-700 py-2 px-4">{bill.title}</td>
                  <td className="border-t border-gray-700 py-2 px-4">{bill.amount}</td>
                  <td className="border-t border-gray-700 py-2 px-4">{bill.dueDate}</td>
                  <td className="border-t border-gray-700 py-2 px-4">{bill.merchantAddress}</td>
                  <td className="border-t border-gray-700 py-2 px-4">{bill.recurring ? 'Yes' : 'No'}</td>
                  <td className="border-t border-gray-700 py-2 px-4">{bill.interval}</td>
                  <td className="border-t border-gray-700 py-2 px-4">{bill.paid ? 'Paid' : 'Pending'}</td>
                  <td className="border-t border-gray-700 py-2 px-4">
                    {!bill.paid && (
                      <button
                        className="px-4 py-2 bg-green-600 rounded-lg text-white"
                        onClick={() => payBill(bill)}
                      >
                        Pay Now
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No bills found for this address</p>
        )}
      </div>
    </div>
  );
};

export default BillList;
