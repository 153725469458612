import React, { useRef, useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { db } from './firebaseConfig';
import { SiweMessage } from 'siwe';
import { checksumAddress } from 'viem';

const CreateBill = ({ provider }) => {
  const titleRef = useRef();
  const amountRef = useRef();
  const dueDateRef = useRef();
  const merchantAddressRef = useRef();
  const [isRecurring, setIsRecurring] = useState(false);
  const intervalRef = useRef();
  const [userAddress, setUserAddress] = useState('');

  useEffect(() => {
    const getUserAddress = async () => {
      try {
        const addresses = await provider.request({ method: 'eth_requestAccounts' });
        setUserAddress(checksumAddress(addresses[0]));  // Checksum the address
      } catch (error) {
        console.error('Error getting user address:', error);
        toast.error('Please connect your wallet');
      }
    };

    getUserAddress();
  }, [provider]);

  const generateUniqueId = async () => {
    const querySnapshot = await getDocs(collection(db, 'bills'));
    const billIds = querySnapshot.docs.map(doc => doc.data().id);
    let newId = 1;
    while (billIds.includes(newId)) {
      newId += 1;
    }
    return newId;
  };

  const signInWithEthereum = async (provider) => {
    try {
      const message = new SiweMessage({
        domain: window.location.host,
        address: userAddress, // Normalize the case of the address
        statement: 'Sign in with Ethereum to the application.',
        uri: window.location.origin,
        version: '1',
        chainId: 1,
      });

      const messageToSign = message.prepareMessage();
      const signature = await provider.request({
        method: 'personal_sign',
        params: [messageToSign, userAddress],
      });

      const recoveredAddress = await message.recoverAddress(signature);
      if (recoveredAddress.toLowerCase() !== userAddress.toLowerCase()) {
        throw new Error('Address mismatch');
      }

      return true;
    } catch (error) {
      console.error('Error during SIWE sign-in:', error);
      toast.error('Sign-In with Ethereum failed.');
      return false;
    }
  };

  const createBill = async (title, amount, dueDate, merchantAddress, recurring, interval, address) => {
    try {
      if (!title || !amount || !dueDate || !merchantAddress || !address || (recurring && !interval)) {
        toast.error('Please fill in all fields');
        return;
      }
      toast.info('Saving bill...');

      const isAuthenticated = await signInWithEthereum(provider);

      if (!isAuthenticated) {
        toast.error('Sign In With Ethereum Failed');
        return;
      }

      const billId = await generateUniqueId();

      const billData = {
        id: billId,
        title,
        amount,
        dueDate,
        merchantAddress,
        recurring,
        interval,
        address,
      };

      await addDoc(collection(db, 'bills'), billData);

      toast.success('Bill created successfully!');
      window.location.href = '/dapp/dashboard/list';
    } catch (error) {
      console.error('Error creating bill:', error);
      if (error.message === 'Ethereum provider not available') {
        toast.error('Ethereum provider not available. Please ensure your wallet is connected.');
      } else {
        toast.error('Failed to create bill.');
      }
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <ToastContainer />
      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Create Bill</h2>
        <div className="flex flex-col gap-4">
          <input
            ref={titleRef}
            type="text"
            placeholder="Bill Title"
            className="block w-full mb-4 px-4 py-2 bg-gray-700 rounded-lg"
          />
          <input
            ref={amountRef}
            type="number"
            placeholder="Amount (in USD)"
            className="block w-full mb-4 px-4 py-2 bg-gray-700 rounded-lg"
          />
          <input
            ref={dueDateRef}
            type="date"
            placeholder="Due Date"
            className="block w-full mb-4 px-4 py-2 bg-gray-700 rounded-lg"
          />
          <input
            ref={merchantAddressRef}
            type="text"
            placeholder="Merchant Address"
            className="block w-full mb-4 px-4 py-2 bg-gray-700 rounded-lg"
          />
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={isRecurring}
              onChange={() => setIsRecurring(!isRecurring)}
              className="mr-2"
            />
            <span>Recurring Bill</span>
          </div>
          {isRecurring && (
            <input
              ref={intervalRef}
              type="number"
              placeholder="Interval (in days)"
              className="block w-full mb-4 px-4 py-2 bg-gray-700 rounded-lg"
            />
          )}
          <input
            type="text"
            placeholder="Wallet Address"
            value={userAddress}
            readOnly
            className="block w-full mb-4 px-4 py-2 bg-gray-700 rounded-lg"
          />
        </div>
        <button
          className="px-4 py-2 bg-green-600 rounded-lg text-white mt-4"
          onClick={() =>
            createBill(
              titleRef.current.value,
              amountRef.current.value,
              dueDateRef.current.value,
              merchantAddressRef.current.value,
              isRecurring,
              intervalRef.current?.value || '',
              userAddress
            )
          }
        >
          Create Bill
        </button>
      </div>
    </div>
  );
};

export default CreateBill;
